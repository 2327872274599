var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import "./App.css";
import { getParams } from "./lib/browser";
import Container from "@material-ui/core/Container";
import { Button, InputAdornment, TextField, } from "@material-ui/core";
import { useMutation } from "react-query";
import { chargeUser } from "shared-library";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
function App() {
    var _a = process.env, _b = _a.REACT_APP_APP_URL, REACT_APP_APP_URL = _b === void 0 ? "" : _b, _c = _a.REACT_APP_BM_CLIENT_ID, REACT_APP_BM_CLIENT_ID = _c === void 0 ? "" : _c;
    var redirectUri = encodeURIComponent(REACT_APP_APP_URL);
    var params = getParams();
    var username = params.get("username");
    var accessToken = params.get("access_token");
    var enableUrl = "https://www.beeminder.com/apps/authorize?client_id=".concat(REACT_APP_BM_CLIENT_ID, "&redirect_uri=").concat(redirectUri, "&response_type=token");
    var _d = useState(1), amount = _d[0], setAmount = _d[1];
    var _e = useState(""), note = _e[0], setNote = _e[1];
    var isAuthenticated = username && accessToken;
    var mutate = useMutation("charge", function () {
        if (!isAuthenticated) {
            throw new Error("Must be authenticated to make charge");
        }
        if (!amount) {
            throw new Error("Must specify amount to be charged");
        }
        return chargeUser(accessToken, amount, note);
    }, {
        onSettled: function (data, error, variables, context) {
            console.log({ data: data, error: error, variables: variables, context: context });
        },
    }).mutate;
    return _jsxs(Container, __assign({ className: "App" }, { children: [_jsx("h1", { children: "Take My Money" }, void 0), _jsx("p", { children: "Find yourself needing to send Beeminder some extra money? You can do it here." }, void 0), !isAuthenticated && _jsx("p", { children: _jsx(Button, __assign({ variant: "contained", color: "primary", href: enableUrl }, { children: "Log in with Beeminder" }), void 0) }, void 0), isAuthenticated && _jsxs(_Fragment, { children: [_jsxs("p", { children: ["Logged in as Beeminder user: ", _jsx("strong", { children: _jsx("a", __assign({ href: "https://beeminder.com/".concat(username), target: "_blank", rel: "nofollow noreferrer" }, { children: username }), void 0) }, void 0)] }, void 0), _jsx(TextField, { label: "Amount", type: "number", value: amount, onChange: function (e) {
                            var num = Number(e.target.value);
                            setAmount(num > 1 ? num : 1);
                        }, InputProps: {
                            startAdornment: (_jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx(AttachMoneyIcon, {}, void 0) }), void 0)),
                        } }, void 0), _jsx("br", {}, void 0), _jsx("br", {}, void 0), _jsx(TextField, { label: "Note", multiline: true, value: note, onChange: function (e) { return setNote(e.target.value); } }, void 0), _jsx("br", {}, void 0), _jsx("br", {}, void 0), _jsx(Button, __assign({ variant: "contained", onClick: function () { return mutate(); } }, { children: "Send my money to Beeminder" }), void 0)] }, void 0), _jsxs("p", { children: ["Thanks to ", _jsx("a", __assign({ href: "https://icons8.com/" }, { children: "Icons8" }), void 0), " for favicon"] }, void 0)] }), void 0);
}
export default App;
